<template>
  <b-modal
    v-model="isOpen"
    centered
    title="Финальный статус"
    header-class="monitoring-final-status-header"
    @hidden="onClose"
    @ok="onClose"
    @close="onClose"
  >
    <template>
      <div class="flex justify-center flex-column">
        <v-select
          v-model="finalStatus"
          :reduce="item => item.value"
          :options="finalStatuses"
          placeholder="Финальный статус"
          label="title"
          class="crm-select"
          @input="onChangeValue"
        >
          <template #no-options>
            Ничего не найдено
          </template>
        </v-select>
        <small
          v-if="isSameStatus"
          class="text-v-ui-root-service-alarm"
        >
          Данный статус уже установлен
        </small>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :disabled="!finalStatus?.length || isSameStatus"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="setFinalStatus"
      >
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { FINAL_STATUS_TYPE } from '@/helpers/consts';
import { vtbFormService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'FinalStatusModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    patientId: {
      type: Number,
      default: null,
    },
    previousFinalStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      finalStatus: null,
      isOpen: true,
      wasChanged: false,
    };
  },
  computed: {
    finalStatuses() {
      return FINAL_STATUS_TYPE;
    },
    isSameStatus() {
      return this.previousFinalStatus === this.finalStatus && this.finalStatus !== null;
    },
  },
  created() {
    if (this.previousFinalStatus?.length) this.finalStatus = this.previousFinalStatus;
  },
  methods: {
    async setFinalStatus() {
      try {
        await vtbFormService.setFinalStatus({ patientId: this.patientId, finalStatus: this.finalStatus });
        await this.$emit('refreshSearch');
        this.onClose();
      } catch (e) {
        console.warn(e);
        showErrorCustomMessage('Не удалось изменить финальный статус');
      }
    },
    onClose() {
      this.$emit('input', false);
    },
    onChangeValue() {
      if (!this.wasChanged) this.wasChanged = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.monitoring-final-status-header {
  font-weight: 500;
}
</style>
